import { Subject } from 'rxjs';
import { Card } from '@/core/models';

class Bus {
    moveCardToOriginCmd$ = new Subject<MoveCardToOriginCmd>();

    drawCardCmd$ = new Subject();

    moveCardToPairCardCmd$ = new Subject<MoveCardToPairCardCmd>();

    discardKingCmd$ = new Subject<DiscardKingCmd>();

    recycleWasteCmd$ = new Subject();
}

export type MoveCardToOriginCmd = {
    card: Card;
};

export type MoveCardToPairCardCmd = {
    card1: Card;
    card2: Card;
};

export type DiscardKingCmd = {
    card: Card;
};

export const bus = new Bus();
