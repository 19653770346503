import { Card, CardOwner } from '@/core/models';
import { cardsQuery } from '@/state/cards/cards.query';

class PyramidUtil {
    getRowNumber(order: number) {
        if (order == 1) {
            return 1;
        } else if (order >= 2 && order <= 3) {
            return 2;
        } else if (order >= 4 && order <= 6) {
            return 3;
        } else if (order >= 7 && order <= 10) {
            return 4;
        } else if (order >= 11 && order <= 15) {
            return 5;
        } else if (order >= 16 && order <= 21) {
            return 6;
        } else if (order >= 22 && order <= 28) {
            return 7;
        }
        throw new Error('Order out of range');
    }

    getIndexInRow(order: number) {
        const row = this.getRowNumber(order);
        return order - this.sumTo(row - 1);
    }

    getCoverOrders(order: number): { left: number; right: number } | null {
        const row = this.getRowNumber(order);
        if (row == 7) {
            return null;
        }
        return { left: order + row, right: order + row + 1 };
    }

    private sumTo(n: number) {
        return (n * (n + 1)) / 2;
    }

    getPyramidExposedCards() {
        const cards = cardsQuery.getAll().filter((c) => c.owner == CardOwner.pyramid);
        return cards.filter((c) => this.isPyramidCardExposed(c));
    }

    isPyramidCardExposed(card: Card) {
        const coverOrders = this.getCoverOrders(card.order);
        // if no cover orders then this is last row in pyramid then it exposed
        if (!coverOrders) {
            return true;
        }
        // if cover orders not exists in pyramid then the card not exposed
        const cards = cardsQuery.getAll().filter((c) => c.owner == CardOwner.pyramid);
        const left = cards.filter((c) => c.order == coverOrders.left).length > 0;
        const right = cards.filter((c) => c.order == coverOrders.right).length > 0;
        return !(left || right);
    }

    isCardExposed(card: Card) {
        // if cards in stock or waste make sure their on the top
        if (card.owner == CardOwner.stock) {
            const top = cardsQuery.getTopByOwner(CardOwner.stock);
            if (top && top.id == card.id) {
                return true;
            }
        }

        // if cards in waste or waste make sure their on the top
        if (card.owner == CardOwner.waste) {
            const top = cardsQuery.getTopByOwner(CardOwner.waste);
            if (top && top.id == card.id) {
                return true;
            }
        }

        // if card in pyramid check if exposed
        return this.isPyramidCardExposed(card);
    }
}

export const pyramidUtil = new PyramidUtil();
