import { gameService } from '@/state/game/game.service';
import { Card, CardOwner, GameStatus, Suit } from '@/core/models';
import { cardsService } from '@/state/cards/cards.service';
import { cardsQuery } from '@/state/cards/cards.query';
import { gameQuery } from '@/state/game/game.query';
import { coreBus } from '@/core/core-bus';
import { BaseGameController } from '@/core/base-game.controller';
import { moveHistory } from '@/core/move-history';
import { pyramidUtil } from '@/games/pyramid/pyramid-util';
import { range } from 'lodash';

export class GameController extends BaseGameController {
    protected initGame(): void {
        gameService.setStockRecycleCounter(2);
        cardsService.resetAllCards(CardOwner.stock);
        moveHistory.reset();
    }

    protected isGameCompleted(): boolean {
        return cardsQuery.getAllByOwner(CardOwner.pyramid).length == 0;
    }

    constructor() {
        super();
        this.subscription.add(
            coreBus.gameMoveCompletedEvent$.subscribe(() => {
                // we only start the game after user made first move
                if (gameQuery.getValue().gameStatus == GameStatus.dealCompleted) {
                    gameService.setGameStatus(GameStatus.running);
                }
                this.updateCardsStatus();
            })
        );
        this.subscription.add(
            gameQuery.gameStatus$.subscribe((status) => {
                if (status == GameStatus.dealCompleted) {
                    if (process.env.NODE_ENV === 'development') {
                        setTimeout(() => {
                            // this.setCustomBoard();
                        }, 2000);
                    }
                }
            })
        );
        this.initStage();
    }

    private initStage() {
        const suits = [Suit.Diamond, Suit.Spade, Suit.Club, Suit.Heart];
        const cards = suits.flatMap((s) => {
            return range(1, 14).map(
                (r) =>
                    ({
                        id: `${r}${s}`,
                        suit: s,
                        rank: r,
                        order: 0,
                        dragEnabled: false,
                        isShadow: false,
                        owner: CardOwner.stock,
                    } as Card)
            );
        });
        cardsService.init(cards);
    }

    private updateCardsStatus() {
        // enable drag to all exposed pyramid card
        const exposedIds = pyramidUtil.getPyramidExposedCards().map((c) => c.id);
        cardsService.updateByIds(exposedIds, {
            dragEnabled: true,
        });

        // make sure top stock card is face up and drag enabled
        const top = cardsQuery.getTopByOwner(CardOwner.stock);
        if (top) {
            cardsService.update(top.id, {
                isFaceUp: true,
                dragEnabled: true,
            });
        }
    }

    private setCustomBoard() {
        const r1 = ['7C'];
        const r2 = ['12H', '5S'];
        const r3 = ['9S', '8D', '10C'];
        const r4 = ['11H', '6S', '10H', '4S'];
        const r5 = ['11D', '10D', '4C', '7H', '7S'];
        const r6 = ['6C', '8C', '4H', '3C', '9C', '13C'];
        const r7 = ['', '', '', '', '6H', '2H', ''];

        const pyramidIds = r1.concat(r2).concat(r3).concat(r4).concat(r5).concat(r6).concat(r7);

        const pyramid = pyramidIds.map((id, i) => {
            const card = cardsQuery.getEntity(id);
            return {
                // eslint-disable-next-line
                ...card!,
                owner: id != '' ? CardOwner.pyramid : CardOwner.discard,
                order: i + 1,
                dragEnabled: false,
                isFaceUp: true,
            };
        });

        const stock = cardsQuery
            .getAll()
            .filter((c) => pyramidIds.indexOf(c.id) < 0)
            .map((c, i) => ({
                ...c,
                owner: CardOwner.stock,
                order: i + 1,
                dragEnabled: false,
            }));

        const all = pyramid.concat(stock);
        cardsService.upsertMany(all);
        all.forEach((c) => {
            coreBus.cardMoveCmd$.next({
                cardId: c.id,
                duration: 0,
            });
        });

        this.handleMoveCompleted();
    }
}
