import { bus } from '@/games/pyramid/bus';
import { Card, CardOwner, Hint } from '@/core/models';
import { cardsQuery } from '@/state/cards/cards.query';
import { judge } from '@/games/pyramid/judge';
import { maxBy, uniqBy, orderBy } from 'lodash';
import { coreBus } from '@/core/core-bus';
import { pyramidUtil } from '@/games/pyramid/pyramid-util';
import { CardAutoMoveBaseController } from '@/core/card-auto-move.base.controller';

export class AutoMoveController extends CardAutoMoveBaseController {
    protected doBestMove(card: Card) {
        // if card rank is 13 it should discard
        if (card.rank == 13) {
            bus.discardKingCmd$.next({
                card,
            });
            return;
        }

        // check card can paired with exposed pyramid card
        const canPaired = pyramidUtil
            .getPyramidExposedCards()
            .filter((c) => c.id != card.id && judge.canPairCards(card, c));
        if (canPaired.length > 0) {
            const max = maxBy(canPaired, (c) => c.order);
            if (max) {
                bus.moveCardToPairCardCmd$.next({
                    card1: card,
                    card2: max,
                });
            }
            return;
        }

        // check can paired with stock
        if (card.owner != CardOwner.stock) {
            const top = cardsQuery.getTopByOwner(CardOwner.stock);
            if (top && judge.canPairCards(card, top)) {
                bus.moveCardToPairCardCmd$.next({
                    card1: card,
                    card2: top,
                });
                return;
            }
        }

        // check can paired with waste
        if (card.owner != CardOwner.waste) {
            const top = cardsQuery.getTopByOwner(CardOwner.waste);
            if (top && judge.canPairCards(card, top)) {
                bus.moveCardToPairCardCmd$.next({
                    card1: card,
                    card2: top,
                });
                return;
            }
        }

        coreBus.shakeCardCmd$.next({
            card,
        });
    }

    protected isAutoFinishPossible() {
        return false;
    }

    protected doAutoFinish(done: () => void) {
        done();
    }

    protected generateHints() {
        const topStock = cardsQuery.getTopByOwner(CardOwner.stock);
        const topWaste = cardsQuery.getTopByOwner(CardOwner.waste);
        const cards = cardsQuery
            .getAllByOwner(CardOwner.pyramid)
            .filter((c) => pyramidUtil.isCardExposed(c));

        const kings = cards.filter((c) => c.rank == 13);

        if (topStock) {
            if (topStock.rank == 13) {
                kings.push(topStock);
            } else {
                cards.push(topStock);
            }
        }
        if (topWaste) {
            if (topWaste.rank == 13) {
                kings.push(topWaste);
            } else {
                cards.push(topWaste);
            }
        }

        const hints: { key: string; hint: Hint }[] = [];
        for (let i = 0; i < cards.length; i++) {
            for (let j = 0; j < cards.length; j++) {
                if (i != j) {
                    if (judge.canPairCards(cards[i], cards[j])) {
                        hints.push({
                            key: orderBy([cards[i].id, cards[j].id], (x) => x).join(''),
                            hint: {
                                card1Id: cards[i].id,
                                card2Id: cards[j].id,
                                priority: 0,
                            },
                        });
                    }
                }
            }
        }

        const uniq = uniqBy(hints, (x) => x.key).map((x) => x.hint);

        const kinkHints = kings.map(
            (c) =>
                ({
                    card1Id: c.id,
                } as Hint)
        );

        return uniq.concat(kinkHints);
    }
}
