<template>
    <card-game-stage :controllers="controllersInst" :display="displayInst" :show-hint-button="true">
        <template v-slot:frames>
            <frames />
            <draw-button />
        </template>
    </card-game-stage>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';
import { controllers } from '@/games/pyramid/controllers/controllers';
import Frames from '@/games/pyramid/views/Frames.vue';
import CardGameStage from '@/components/CardGameStage.vue';
import DrawButton from '@/games/pyramid/views/DrawButton.vue';
import { display } from '@/games/pyramid/display';

export default defineComponent({
    components: {
        Frames,
        CardGameStage,
        DrawButton,
    },

    setup() {
        const controllersInst = computed(() => controllers);
        const displayInst = computed(() => display);

        return {
            controllersInst,
            displayInst,
        };
    },
});
</script>
