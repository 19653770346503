import { Card } from '@/core/models';
import { pyramidUtil } from '@/games/pyramid/pyramid-util';

export class Judge {
    canPairCards(card1: Card, card2: Card) {
        if (!pyramidUtil.isCardExposed(card1)) {
            return false;
        }
        if (!pyramidUtil.isCardExposed(card2)) {
            return false;
        }
        return card1.rank + card2.rank == 13;
    }
}

export const judge = new Judge();
