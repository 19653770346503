<template>
    <div>
        <div @click="resetStock" class="frame clickable stock noselect" :style="stockStyle">
            <div class="counter" :style="counterStyle">{{ recycleCounter }}</div>
            <icon :icon="icon" :style="restartIconStyle"></icon>
        </div>
        <div class="frame" :style="wasteStyle"></div>
    </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue';
import { subscribeTo, useObservable } from '@/core/rxjs-helpers';
import { CardOwner } from '@/core/models';
import { gameQuery } from '@/state/game/game.query';
import { cardsQuery } from '@/state/cards/cards.query';
import { bus } from '@/games/pyramid/bus';
import Icon from '@/components/Icon.vue';
import { display } from '@/games/pyramid/display';
import { recomputable } from '@/composable/recomputable';

export default defineComponent({
    components: { Icon },

    setup() {
        const recompute = ref(0);
        const recycleCounter = useObservable(gameQuery.stockRecycleCounter$);

        subscribeTo(gameQuery.gameSize$, () => {
            setTimeout(() => {
                recompute.value += 1;
            });
        });

        const getFrameStyle = (x: number, y: number) => {
            return {
                top: `${y}px`,
                left: `${x}px`,
                width: `${display.cardSize.width}px`,
                height: `${display.cardSize.height}px`,
                borderRadius: `${display.cardSize.width * 0.06}px`,
            };
        };

        const stockStyle = recomputable(recompute, () => {
            const frame = getFrameStyle(display.stockPosition.x, display.stockPosition.y);
            return {
                ...frame,
            };
        });

        const restartIconStyle = recomputable(recompute, () => {
            const s = display.cardSize.width * 0.4;
            return {
                height: `${s}px`,
                width: `${s}px`,
            };
        });

        const counterStyle = recomputable(recompute, () => {
            const w = display.cardSize.width;
            return {
                fontSize: `${w * 0.3}px`,
                marginBottom: `${w * 0.1}px`,
            };
        });

        const wasteStyle = recomputable(recompute, () => {
            return getFrameStyle(display.wastePosition.x, display.wastePosition.y);
        });

        const icon = computed(() => {
            return recycleCounter.value == 0 ? 'ban' : 'undo';
        });

        const resetStock = () => {
            if (cardsQuery.getAllByOwner(CardOwner.stock).length == 0) {
                bus.recycleWasteCmd$.next();
            }
        };

        return {
            stockStyle,
            resetStock,
            restartIconStyle,
            wasteStyle,
            recycleCounter,
            counterStyle,
            icon,
        };
    },
});
</script>

<style scoped>
.frame {
    border: #777 1px solid;
    position: absolute;
    background-color: rgba(50, 50, 50, 0.5);
    pointer-events: none;
}
.frame.clickable {
    pointer-events: all;
    cursor: pointer;
}
.frame.stock {
    text-align: center;
    vertical-align: center;
}
.frame .counter {
    color: #bbb;
}
</style>
