import { Controller } from '@/core/controller';
import { bus } from '@/games/pyramid/bus';
import { Card, CardOwner, GameStatus } from '@/core/models';
import { cardsQuery } from '@/state/cards/cards.query';
import { cardsService } from '@/state/cards/cards.service';
import { judge } from '@/games/pyramid/judge';
import { gameQuery } from '@/state/game/game.query';
import { coreUtil } from '@/core/core-util';
import { moveHistory } from '@/core/move-history';
import { gameService } from '@/state/game/game.service';
import { coreBus } from '@/core/core-bus';
import { cardSound } from '@/core/sound';

export class MoveController extends Controller {
    constructor() {
        super();
        this.subscription.add(
            bus.moveCardToOriginCmd$.subscribe((ev) => {
                this.moveCardToOrigin(ev.card);
                coreBus.gameMoveCompletedEvent$.next();
            })
        );
        this.subscription.add(
            bus.drawCardCmd$.subscribe(async () => {
                cardSound.playMove();
                await this.drawCard();
                coreBus.gameMoveCompletedEvent$.next();
            })
        );
        this.subscription.add(
            bus.moveCardToPairCardCmd$.subscribe(async (ev) => {
                await this.pairCards(ev.card1, ev.card2);
                coreBus.gameMoveCompletedEvent$.next();
            })
        );
        this.subscription.add(
            bus.discardKingCmd$.subscribe(async (cmd) => {
                await this.discardKing(cmd.card);
                coreBus.gameMoveCompletedEvent$.next();
            })
        );
        this.subscription.add(
            coreBus.undoMoveCmd$.subscribe(() => {
                this.undoLastMove();
            })
        );
        this.subscription.add(
            bus.recycleWasteCmd$.subscribe(() => {
                this.recycleWaste();
                coreBus.gameMoveCompletedEvent$.next();
            })
        );
    }

    private moveCardToOrigin(card: Card) {
        coreBus.cardMoveCmd$.next({
            cardId: card.id,
            duration: 300,
        });
    }

    private async drawCard() {
        const card = cardsQuery.getTopByOwner(CardOwner.stock);
        if (!card) {
            this.recycleWaste();
            return;
        }

        moveHistory.startMove();
        const top = cardsQuery.getTopByOwner(CardOwner.waste);
        cardsService.update(card.id, {
            isFaceUp: true,
            dragEnabled: true,
            order: top ? top.order + 1 : 1,
            owner: CardOwner.waste,
        });
        moveHistory.addState(card);
        coreBus.cardMoveCmd$.next({
            cardId: card.id,
            duration: 300,
        });
        gameService.increaseMoveCounter();
        moveHistory.endMove();

        return coreUtil.delay(300);
    }

    private async pairCards(card1: Card, card2: Card) {
        if (!judge.canPairCards(card1, card2) || !this.canMakeMove()) {
            this.moveCardToOrigin(card1);
            return;
        }

        moveHistory.startMove();

        // update card1 to same as card2
        cardsService.update(card1.id, {
            owner: card2.owner,
            order: card2.order,
        });

        // move card1 to card2
        coreBus.cardMoveCmd$.next({
            cardId: card1.id,
            duration: 300,
        });

        cardSound.playScore();

        await coreUtil.delay(300);

        // discard pair
        cardsService.updateByIds([card1.id, card2.id], {
            owner: CardOwner.discard,
            order: 99,
        });
        [card1, card2].forEach((card) => {
            coreBus.cardSlidOutDownCmd$.next({
                card,
            });
        });
        moveHistory.addState(card1);
        moveHistory.addState(card2);
        gameService.increaseMoveCounter();
        moveHistory.endMove();
    }

    private async discardKing(card: Card) {
        if (card.rank != 13 || !this.canMakeMove()) {
            this.moveCardToOrigin(card);
            return;
        }

        moveHistory.startMove();

        cardsService.update(card.id, {
            owner: CardOwner.discard,
            order: 99,
        });
        coreBus.cardSlidOutDownCmd$.next({
            card,
        });

        moveHistory.addState(card);
        gameService.increaseMoveCounter();
        moveHistory.endMove();

        return coreUtil.delay(300);
    }

    private recycleWaste() {
        if (!this.canMakeMove()) {
            return;
        }
        if (gameQuery.getValue().stockRecycleCounter <= 0) {
            return;
        }

        const cards = cardsQuery.getAllByOwner(CardOwner.waste);
        if (cards.length == 0) {
            return;
        }

        moveHistory.startMove();

        const updates = cards.map((c) => {
            moveHistory.addState(c);
            return {
                ...c,
                owner: CardOwner.stock,
                isFaceUp: false,
                order: cards.length - c.order + 1,
                dragEnabled: false,
                clickEnabled: cards.length == cards.length - c.order + 1,
            };
        });
        cardsService.upsertMany(updates);
        cards.forEach((c) => {
            coreBus.cardMoveCmd$.next({
                duration: 300,
                cardId: c.id,
            });
        });

        gameService.reduceStockRecycleCounter();
        gameService.increaseMoveCounter();
        moveHistory.endMove();
    }

    private undoLastMove() {
        if (!this.canMakeMove()) {
            return true;
        }

        const move = moveHistory.pop();
        if (move) {
            cardsService.upsertMany(move.cards);
            move.cards.forEach((c) => {
                coreBus.cardMoveCmd$.next({
                    cardId: c.id,
                    duration: 300,
                });
            });
            gameService.increaseMoveCounter();
            gameService.increaseUndoCount();
        }
    }

    private canMakeMove() {
        const status = gameQuery.getValue().gameStatus;
        return status == GameStatus.running || status == GameStatus.dealCompleted;
    }
}
