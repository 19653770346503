import { Controller } from '@/core/controller';
import { bus } from '@/games/pyramid/bus';
import { cardsService } from '@/state/cards/cards.service';
import { cardsQuery } from '@/state/cards/cards.query';
import { CardOwner, Position } from '@/core/models';
import { coreBus, CardDragEvent, DragEventType } from '@/core/core-bus';
import { pyramidUtil } from '@/games/pyramid/pyramid-util';
import { display } from '@/games/pyramid/display';

export class DragController extends Controller {
    constructor() {
        super();
        this.subscription.add(
            coreBus.cardDragEvent$.subscribe((ev) => {
                this.handleEvent(ev);
            })
        );
    }

    private handleEvent(ev: CardDragEvent) {
        if (ev.type == DragEventType.start) {
            this.handleDragStart(ev);
        }
        if (ev.type == DragEventType.end) {
            this.handleDragEnd(ev);
        }
        if (ev.type == DragEventType.move) {
            this.handleDragMove(ev);
        }
    }

    private handleDragStart(ev: CardDragEvent) {
        cardsService.update(ev.cardId, {
            isDragging: true,
        });
    }

    private handleDragEnd(ev: CardDragEvent) {
        cardsService.removeAllHighlight();
        const card = cardsQuery.getEntity(ev.cardId);
        if (!card) {
            throw new Error('could not found card');
        }

        const intExposePyramidCard = this.getIntersectedPyramidCard(ev);
        const isIntWaste = this.isIntersectedPosition(ev, display.wastePosition);
        const isIntStock = this.isIntersectedPosition(ev, display.stockPosition);
        if (intExposePyramidCard) {
            bus.moveCardToPairCardCmd$.next({
                card1: ev.card,
                card2: intExposePyramidCard,
            });
        } else if (isIntStock && card.owner != CardOwner.stock) {
            const top = cardsQuery.getTopByOwner(CardOwner.stock);
            if (top) {
                bus.moveCardToPairCardCmd$.next({
                    card1: ev.card,
                    card2: top,
                });
            }
        } else if (isIntWaste && card.owner != CardOwner.waste) {
            const top = cardsQuery.getTopByOwner(CardOwner.waste);
            if (top) {
                bus.moveCardToPairCardCmd$.next({
                    card1: ev.card,
                    card2: top,
                });
            }
        } else {
            bus.moveCardToOriginCmd$.next({
                card,
            });
        }

        cardsService.update(card.id, {
            isDragging: false,
        });
    }

    private handleDragMove(ev: CardDragEvent) {
        cardsService.removeAllHighlight();
        const intExposePyramidCard = this.getIntersectedPyramidCard(ev);
        if (intExposePyramidCard) {
            cardsService.setHighlight(intExposePyramidCard.id);
        }
    }

    private getIntersectedPyramidCard(ev: CardDragEvent) {
        const cards = pyramidUtil.getPyramidExposedCards();
        const cardW = display.cardSize.width;
        const cardH = display.cardSize.height;

        // get top cards that intersects with the dragged card
        const intersects = [];
        for (let i = 0; i < cards.length; i++) {
            const card = cards[i];
            if (card.id == ev.cardId) {
                continue;
            }
            // check if intersect
            const topPos = display.calcCardPosition(card);
            if (
                !(
                    topPos.x > ev.x + cardW ||
                    topPos.x + cardW < ev.x ||
                    topPos.y > ev.y + cardH ||
                    topPos.y + cardH < ev.y
                )
            ) {
                intersects.push(card);
            }
        }

        // if more then one intersect get the closest one
        if (intersects.length == 0) {
            return null;
        }

        // select min distance
        let selected = intersects[0];
        if (intersects.length > 1) {
            for (let i = 1; i < intersects.length; i++) {
                const int = intersects[i];
                const intPosition = display.calcCardPosition(int);
                const selectedPosition = display.calcCardPosition(selected);
                if (Math.abs(intPosition.x - ev.x) < Math.abs(selectedPosition.x - ev.x)) {
                    selected = int;
                }
            }
        }

        return selected;
    }

    private isIntersectedPosition(ev: CardDragEvent, pos: Position) {
        const cardW = display.cardSize.width;
        const cardH = display.cardSize.height;
        return !(
            pos.x > ev.x + cardW ||
            pos.x + cardW < ev.x ||
            pos.y > ev.y + cardH ||
            pos.y + cardH < ev.y
        );
    }
}
