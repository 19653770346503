import { Card, CardOwner, Position, Size } from '@/core/models';
import { pyramidUtil } from '@/games/pyramid/pyramid-util';
import { CardDisplayBase } from '@/core/card-display.base';

export class Display extends CardDisplayBase {
    pyramidTopPosition: Position = { x: 0, y: 0 };
    stockPosition: Position = { x: 0, y: 0 };
    drawPosition: Position = { x: 0, y: 0 };
    wastePosition: Position = { x: 0, y: 0 };
    drawSize: Size = { width: 0, height: 0 };

    constructor() {
        super(7);
    }

    calcDisplay() {
        const cardWidth = this.cardSize.width;
        const cardHeight = this.cardSize.height;
        const spaceSize = this.spaceHorizontal;

        this.pyramidTopPosition = {
            x: this.gameSize.width / 2 - cardWidth / 2,
            y: spaceSize,
        };

        this.stockPosition = {
            x: this.gameSize.width / 2 - cardWidth * 2,
            y: cardHeight * 0.5 * 9,
        };

        this.drawSize = {
            width: cardWidth * 1.2,
            height: cardWidth * 0.5,
        };

        this.drawPosition = {
            x: this.gameSize.width / 2 - this.drawSize.width / 2,
            y: this.stockPosition.y + cardHeight / 2 - this.drawSize.height / 2,
        };

        this.wastePosition = {
            x: this.gameSize.width / 2 + cardWidth,
            y: this.stockPosition.y,
        };
    }

    calcCardPosition(card: Card): Position {
        if (card.owner == CardOwner.pyramid) {
            const row = pyramidUtil.getRowNumber(card.order);
            const index = pyramidUtil.getIndexInRow(card.order);
            const y = this.pyramidTopPosition.y + (row - 1) * this.cardSize.height * 0.5;
            const xStart =
                this.pyramidTopPosition.x -
                ((row - 1) / 2) * (this.cardSize.width + this.spaceHorizontal);
            const x = xStart + (index - 1) * (this.cardSize.width + this.spaceHorizontal);
            return {
                x,
                y,
            };
        }

        if (card.owner == CardOwner.stock || card.owner == CardOwner.none) {
            const div = this.gameSize.width > 600 ? 4 : 6;
            return {
                x: this.stockPosition.x - (card.order - 1) / div,
                y: this.stockPosition.y - (card.order - 1) / div,
            };
        }

        if (card.owner == CardOwner.waste) {
            return { ...this.wastePosition };
        }

        if (card.owner == CardOwner.discard) {
            return {
                x: 0 - this.cardSize.width,
                y: this.gameSize.height,
            };
        }

        throw new Error(`could not calc position for ${card.id}  ${card.owner}`);
    }
}

export const display = new Display();
