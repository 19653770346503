import { cardsService } from '@/state/cards/cards.service';
import { cardsQuery } from '@/state/cards/cards.query';
import { CardOwner } from '@/core/models';
import { coreBus } from '@/core/core-bus';
import { CardDealerBaseController } from '@/core/card-dealer.base.controller';

export class DealerController extends CardDealerBaseController {
    constructor() {
        super(30, 28);
    }

    protected dealCard(i: number) {
        const card = cardsQuery.getTopByOwner(CardOwner.stock);
        if (!card) {
            throw new Error('no card in stock');
        }

        // pyramid
        cardsService.update(card.id, {
            owner: CardOwner.pyramid,
            isFaceUp: true,
            dragEnabled: i >= 22,
            order: i,
            isShadow: true,
        });

        coreBus.cardMoveCmd$.next({
            duration: 0,
            cardId: card.id,
        });
    }
}
