<template>
    <button
        @click="drawClick"
        :style="buttonStyle"
        class="draw-btn border s-button orange shadow-gl focus:outline-none"
    >
        Draw
    </button>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import { gameQuery } from '@/state/game/game.query';
import { display } from '../display';
import { subscribeTo } from '@/core/rxjs-helpers';
import { bus } from '@/games/pyramid/bus';
import { recomputable } from '@/composable/recomputable';

export default defineComponent({
    setup() {
        const recompute = ref(0);

        subscribeTo(gameQuery.gameSize$, () => {
            setTimeout(() => {
                recompute.value += 1;
            });
        });

        const buttonStyle = recomputable(recompute, () => {
            return {
                width: `${display.drawSize.width}px`,
                height: `${display.drawSize.height}px`,
                top: `${display.drawPosition.y}px`,
                left: `${display.drawPosition.x}px`,
                borderRadius: `${display.drawSize.height * 0.08}px`,
                lineHeight: `${display.drawSize.height}px`,
                fontSize: `${display.drawSize.height * 0.5}px`,
            };
        });

        const drawClick = () => {
            bus.drawCardCmd$.next();
        };

        return {
            buttonStyle,
            drawClick,
        };
    },
});
</script>

<style scoped>
.draw-btn {
    position: absolute;
    z-index: 99;
    pointer-events: all;
    vertical-align: middle;
}
</style>
